/* App.css */

.Header {
  background: linear-gradient(45deg, #4a90e2, #63b3ed);
  color: white;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header h1 {
  margin: 0;
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
}

.Header h1::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -5px;
  left: 0;
  background-color: #ffd700;
  transition: width 0.3s ease-in-out;
}

.Header h1:hover::after {
  width: 100%;
}

.AvatarMenu {
  position: relative;
}

.AvatarIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AvatarIcon:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.AvatarIcon svg {
  color: white;
}

.AvatarMenu .Dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  overflow: hidden;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

.AvatarMenu .Dropdown.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.Dropdown button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #333;
}

.Dropdown button:hover {
  background: linear-gradient(135deg, rgba(239, 68, 68, 0.1) 0%, rgba(239, 68, 68, 0.3) 100%);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.Content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Subjects, .LeaderboardContainer {
  flex: 1 1 300px;
  margin: 1rem;
}

.LastSubject {
  margin-bottom: 2rem;
}

.DecimalSpace {
  margin-left: 0.05em;
  margin-right: 0.05em;
}

h1, h2 {
  margin-top: 0;
}

button {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.SubjectItem {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.SubjectItem:hover {
  transform: translateY(-2px);
}

.SubjectItem::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.1) 0%, rgba(99, 179, 237, 0.3) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.SubjectItem:hover::before {
  opacity: 1;
}

.SubjectItem h2 {
  color: #4a90e2;
  margin-top: 0;
  position: relative;
}

.SubjectItem button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
}

.SubjectItem button:hover {
  opacity: 0.8;
}

.TimerContainer {
  width: 100%;
  margin-bottom: 1rem;
}

.Timer {
  max-width: 300px;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.TimerDisplay {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

.Timer button {
  display: block;
  width: 100%;
  background-color: #ffd700;
  color: #333;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.Timer button:hover {
  background-color: #ffc700;
}

.Subjects, .LeaderboardContainer {
  flex: 1 1 300px;
  margin: 0 1rem 1rem 0;
}

.Subjects {
  width: 100%;
  max-width: 600px; /* Adjust this value as needed */
  margin: 0 auto;
}

.SubjectList {
  width: 75%;
}

.SubjectItem {
  width: 100%;
}

.new-subject-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.add-subject-button {
  align-self: flex-start;
}

.new-subject-form {
  width: 100%;
  max-width: 400px;
}

.Dashboard {
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.form-appear {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.form-appear-active {
  opacity: 1;
  transform: translateY(0);
}

.form-exit {
  opacity: 1;
}

.form-exit-active {
  opacity: 0;
  transition: opacity 0s ease;
}

.dark .Header {
  background: linear-gradient(45deg, #2c5282, #2b6cb0);
}

.dark .SubjectItem {
  background-color: #2d3748;
  color: #e2e8f0;
}

.dark .SubjectItem::before {
  background: linear-gradient(135deg, rgba(44, 82, 130, 0.1) 0%, rgba(43, 108, 176, 0.3) 100%);
}

.dark .Timer {
  background-color: #2d3748;
  color: #e2e8f0;
}

.dark .Timer button {
  background-color: #4a5568;
  color: #e2e8f0;
}

.dark .Timer button:hover {
  background-color: #718096;
}

.Leaderboard .text-view {
  max-height: 300px;
  overflow-y: auto;
}

.Leaderboard .text-view > div {
  transition: background-color 0.3s ease;
}

.Leaderboard .text-view > div:hover {
  background-color: rgba(75, 192, 192, 0.1);
}